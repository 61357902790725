<template>
  <button
    type="button"
    :class="[variations[variant], {'opacity-50' : disabled}]"
    :disabled="disabled"
  >
    <slot />
  </button>
</template>

<script setup lang="ts">
withDefaults(defineProps<{
  variant?: 'primary' | 'secondary'
  disabled?: boolean
}>(), {
  variant: 'primary',
  disabled: false,
});

const variations = {
  primary: 'rounded-full bg-primary px-3 py-4 text-base leading-none transition hover:bg-secondary',
  secondary: '',
};
</script>
